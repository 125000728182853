<template>
  <div class="Complaintpar">
    <div class="top-titles">
      <div class="title"><span>投诉列表</span>/投诉详情</div>
    </div>

    <!-- 详情-->
    <div class="particulars">
      <div class="particulars-box">
        <div class="particulars-list">
          <span class="pl-tltle">投诉人:</span
          ><span class="pl-content">{{ listpar.wk_name }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">电话:</span
          ><span class="pl-content">{{ listpar.wkbu_mobile }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">被投诉商家:</span
          ><span class="pl-content">{{ listpar.bs_company_name }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">投诉时间:</span
          ><span class="pl-content">{{ listpar.occurDatetime }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">投诉内容:</span
          ><span class="pl-content">{{ listpar.content1 }}</span>
        </div>
      </div>

      <div class="particulars-img">
        <div class="particulars-list">
          <span class="pl-tltle">投诉图片:</span><img :src="listurl" alt="" />
        </div>
      </div>
    </div>

    <div v-if="value1 == 0 ? false : true" class="Complaintpar-xing">
      <span>评价：</span><el-rate disabled v-model="value1"></el-rate>
    </div>
    <div class="pl-contents">
      <el-form ref="form" label-width="50px" style="width: 600px">
        <el-form-item label="意见:">
          <el-input
            type="textarea"
            v-model="listpar.content2"
            rows="2"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="info" @click="yijian()">处理意见</el-button>
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js";
import apiurl from "@/api/apiurl/apiurl.js";
import config from "@/request/config.js";
export default {
  data() {
    return {
      // 上传图片的地址
      upLoadUrl: config.ImgUrl,

      listpar: {},

      listurl: "",
      value1: null,
    };
  },

  created() {
    this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
    async getSong() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家
        bizCatalog: "ComplaintEntity",
        handleMode: "fetchDetail",
        complaintId: this.$route.params.id,
      });
      // console.log(res);
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.listpar = res.Result;
      this.listurl = res.Result.picurl1[0];
      //
      this.value1 = res.Result.fromer_appraise * 1;
    },
    //弹窗
    yijian(e) {
      this.etype = e;
      this.$confirm("请确认", "消息提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消操作",
      })
        .then(() => {
          this.yijians();
        })
        .catch((action) => {
          console.log("取消");
        });
    },
    async yijians() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家
        bizCatalog: "ComplaintEntity",
        handleMode: "replyComplaintServicer",
        complaintId: this.$route.params.id,
        bizData: {
          content: this.listpar.content2,
        },
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.$alert("设置成功", "消息提示", { confirmButtonText: "确定" });
    },
  },
  computed: {},
};
</script>


<style  scoped>
.Complaintpar .top-titles {
  height: 70px;
}
.Complaintpar .particulars-img {
  height: 200px;
}
.particulars-fankui {
  width: 100%;
}
.particulars-fankui .particulars-list {
  width: 100%;
  text-align: center;
}
.particulars-fankui .particulars-list .pl-tltle {
  color: rgb(255, 81, 0);
}
.biy-icon {
  display: block;
  width: 300px;
  margin: 0 auto;
}

.pl-contents {
  width: 100%;
  width: 600px;
  height: 200px;
  margin: 0 auto;
  font-size: 14px;

  padding-left: 10px;
  color: #222;
  text-align: center;
}
.Complaintpar-xing {
  width: 200px;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: -20px;
}
@import "../../../assets/css/parstyle.css";
</style>